import Vue from 'vue'
import Router from 'vue-router'



const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
// const VueRouterReplace = Router.prototype.replace
// Router.prototype.replace = function replace (to) {
//   return VueRouterReplace.call(this, to).catch(err => err)
// }


Vue.use(Router)


export default new Router({
    mode: 'history',
    base:'/',
    routes: [{
            path: '/',
            name: 'index',
            component: () =>
                import ('@/views/index'), // 首页
            meta: {
                keepAlive: false
            }
        },
        {
            path: '/detail',
            name: 'detail',
            component: () =>
                import ('@/views/detail'), // 详情
           
        },
        {
            path: '/game',
            name: 'game',
            component: () =>
                import ('@/views/game'), // 游戏
            meta: {
                keepAlive: false
            }
        },
        {
            path: '/agreement',
            name: 'agreement',
            component: () =>
                import ('@/views/agreement'), // 协议
            meta: {
                keepAlive: false
            }
        }
    ]
})