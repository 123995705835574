<template>
  <div id="app">
    <!-- <keep-alive> -->
      <router-view></router-view>
    <!-- </keep-alive> -->
    <!-- <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view> -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    }
  },
  mounted() {
  }
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  font-size: 0.32rem;
}

#app{
  
  
}

/*单行溢出*/
.oneTxtCut {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.flex {
  display: flex;
  align-items: center;
}
input,
textarea {
  -webkit-user-select: auto;
  margin: 0px;
  padding: 0px;
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0);
}
.van-popup {
  background-color: transparent;
}
.toastClass {
  z-index: 9999;
}
</style>